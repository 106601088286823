import React from "react";
import { Link, withPrefix } from "gatsby";

import Layout from "../../../components/layout";
import SmallBanner from "../../../components/smallBanner";

const SingleOffer = () => {
	const lang = "en";

	return (
		<Layout
			lang={lang}
			seoTitle="Pallet 4.0"
			translationPL="/oferta/paleta-4-0/"
			translationDE="/de/angebot/palette-4-0/"
			isPallet4
		>
			<SmallBanner
				title="Pallet 4.0"
				aditionalBreadcrump="Our services"
				aditionalBreadcrumpLink="/en/our-services/"
			/>

			<section className="pallet4-breaking">
				<div className="container">
					<h2 className="color--main">
						Groundbreaking project on the logistics market
					</h2>
					<div className="row">
						<div className="col-lg-8 offset-lg-4">
							<p>
								<strong>
									The Pallet 4.0, or the Smart Pallet, is a
									new product
								</strong>
								, which, beside its basic transport and goods
								storage functionality is also an information
								carrier, a sensory system, a logistics data
								exchange node. The Pallet 4.0 is a highly
								scalable product offering many possible
								configuration variants.
							</p>
							<img
								className="pallet4-breaking__img--1"
								src={require("../../../assets/img/pallet4-content-image-1.png")}
								alt=""
							/>
							<p>
								<strong>
									The Pallet 4.0 fits in perfectly with the
									ideas of Industry 4.0, the Internet of
									Things (IoT)
								</strong>
								, introducing new tools to raise security and
								logistics process control. This solution is
								supported by modern logistics management
								platforms.
							</p>
						</div>
						<div className="col-lg-4">
							<img
								className="pallet4-breaking__img--2"
								src={require("../../../assets/img/pallet4-content-image-2.png")}
								alt=""
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="pallet4-what">
				<div className="container">
					<h2 className="color--main">What is the Pallet 4.0?</h2>
					<div className="row">
						<div className="col-lg-8 offset-lg-4">
							<p>
								<strong>
									The wooden Pallet 4.0 is manufactured on a
									modern, fully controlled process line
								</strong>
								. This technology enables greater reliability
								and mechanical resistance of the pallets.{" "}
								<strong>
									Inside the pallet there is electronic
									circuitry executing
								</strong>{" "}
								e. g. the following functions:
							</p>

							<div className="row">
								<div className="col-xl-3 col-sm-6">
									<div className="pallet4-what__item">
										<h4>
											sensor
											<br /> systems
										</h4>
										<p>
											responsible for measurement of
											ambient conditions
										</p>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6">
									<div className="pallet4-what__item">
										<h4>product identification systems</h4>
										<p>
											including a patent-protected serial
											number encoding system
										</p>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6">
									<div className="pallet4-what__item">
										<h4>radio communications circuitry</h4>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6">
									<div className="pallet4-what__item">
										<h4>power supply systems</h4>
										<p>
											using Energy harvesting technology
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="pallet4-specs">
				<div className="container">
					<h2 className="color--main">Charakterystyka</h2>
					<div className="row">
						<div className="col-lg-8 offset-lg-4">
							<p>
								Thanks to the use of innovative electronics,{" "}
								<strong>
									the Pallet 4.0 communicates with external
									devices, e. g. phones
								</strong>
								, making it possible to e. g.:
							</p>
							<ul>
								<li>
									identify the pallet serial number, transport
									document reference number, operation
									history, form and ownership. In connection
									with logistics management systems, data on
									the logistics process is exchanged,
								</li>
								<li>register ambient conditions, such as:</li>
							</ul>
						</div>
					</div>

					<br />
					<br />

					<div className="row">
						<div className="col-lg-4">
							<img
								src={require("../../../assets/img/pallet4-content-image-3.png")}
								alt=""
							/>
						</div>
						<div className="col-lg-8">
							<div className="row">
								<div className="col-xl-3 col-md-4">
									<div className="pallet4-specs__item">
										<div>
											<img
												src={require("../../../assets/img/pallet4-content-ico-1.svg")}
												alt=""
											/>
										</div>
										<p>temperature</p>
									</div>
								</div>
								<div className="col-xl-3 col-md-4">
									<div className="pallet4-specs__item">
										<div>
											<img
												src={require("../../../assets/img/pallet4-content-ico-2.svg")}
												alt=""
											/>
										</div>
										<p>humidity</p>
									</div>
								</div>
								<div className="col-xl-3 col-md-4">
									<div className="pallet4-specs__item">
										<div>
											<img
												src={require("../../../assets/img/pallet4-content-ico-3.svg")}
												alt=""
											/>
										</div>
										<p>goods weight</p>
									</div>
								</div>
								<div className="col-xl-3"></div>
								<div className="col-xl-3 col-md-4">
									<div className="pallet4-specs__item">
										<div>
											<img
												src={require("../../../assets/img/pallet4-content-ico-4.svg")}
												alt=""
											/>
										</div>
										<p>mechanical damage, impacts</p>
									</div>
								</div>
								<div className="col-xl-3 col-md-4">
									<div className="pallet4-specs__item">
										<div>
											<img
												src={require("../../../assets/img/pallet4-content-ico-5.svg")}
												alt=""
											/>
										</div>
										<p>pallet tilt</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<br />
					<br />

					<div className="col-lg-8 offset-lg-4">
						<p>
							The radio identification system is necessary at
							autonomous high-rack warehouses. Quick data readouts
							by transport equipment, marker resistance to
							mechanical damage and high data memory capacity
							allow for fail-safe warehousing processes at
							robot-serviced warehouses.
						</p>
						<p>
							The Pallet 4.0 is also characterised by greater
							strength, meaning an increased number of loading
							cycles, limiting potential losses due to the
							breakdown potential of ordinary palletst.
						</p>
					</div>
				</div>
			</section>

			<section className="pallet4-construction">
				<div className="container">
					<h2 className="color--main">Structure</h2>
					<div className="row">
						<div className="col-lg-8 offset-lg-4">
							<h4>
								The pallet 4.0 may be composed o f several
								components:
							</h4>
							<ul>
								<li>a wooden pallet,</li>
								<li>
									robust connectors, installed on a modern
									technological line,
								</li>
								<li>
									electronic circuitry installed in the pallet
									components,
								</li>
								<li>
									mobile radio communications equipment
									(smartphone, RFID UHF reader),
								</li>
								<li>fixed readout devices,</li>
								<li>
									<strong>
										an open service application including
										public libraries (API),
									</strong>
								</li>
								<li>
									dedicated logistics systems integrators.
								</li>
							</ul>
						</div>
					</div>

					<br />
					<br />

					<div className="row">
						<div className="col-lg-4">
							<img
								src={require("../../../assets/img/pallet4-content-image-4.png")}
								alt=""
							/>
						</div>
						<div className="col-lg-8">
							<h4>Wooden pallet: </h4>
							<p>
								Thanks to assembly precision and suitable
								materials, the following was achieved:
							</p>
							<ul>
								<li>
									<strong>high reliability</strong> (ca. 30%
									higher than currently available pallets),
								</li>
								<li>
									<strong>long life</strong> (thanks to better
									stress neutralisation),
								</li>
								<li>
									<strong>better pallet stability</strong>{" "}
									(due to the tight fit of the pallet
									components thanks to suitable assembly
									processes),
								</li>
								<li>
									<strong>
										pallet parameter repeatability
									</strong>{" "}
									(thanks to the utilisation of detailed
									technical inspections).
								</li>
							</ul>
						</div>
					</div>

					<br />
					<br />

					<div className="row">
						<div className="col-lg-4">
							<img
								src={require("../../../assets/img/pallet4-content-image-5.png")}
								alt=""
							/>
						</div>
						<div className="col-lg-8">
							<h4>The electron ic circu itry is composed of: </h4>
							<ul>
								<li>
									<strong>a microprocessor platform</strong>{" "}
									(unique character confirmed by the patent
									process, characterised by very good
									flexibility),
								</li>
								<li>
									<strong>a communications system:</strong>
								</li>
								<ul>
									<li>
										the hardware layer (using the BLE
										protocol i. e. due to very low energy
										consumption),
									</li>
									<li>
										the software layer (using the OPC UA
										standard, allowing pairing with ERP and
										MES system).{" "}
									</li>
								</ul>
							</ul>

							<br />
							<br />
							<p>
								Note that we are presently experiencing very low
								pallet assembly precision levels on the market,
								as indicated by current tests.{" "}
								<strong>
									The Pallet 4.0 should be used in at least
									four loading cycles
								</strong>
								, which would cover loading of the pallet with
								goods, loading with the goods on the truck, and
								unloading.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section className="pallet4-mobile">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<h2 className="color--white">Mobile application</h2>
						</div>
						<div className="col-lg-8">
							<div className="row align-items-end">
								<div className="col-md-9">
									<p>
										<strong>
											Allows direct readout of pallet
											data, hence it is
											<span>
												NOT NECESSARY TO PURCHASE
												DEDICATED EQUIPMENT
											</span>
											.
										</strong>
									</p>
									<p>
										The provided communications protocols
										allow independent product development,
										adaptation of the data format to
										customer systems and free integration
										with logistics platform.
									</p>
								</div>
								<div className="col-md-3">
									<img
										src={require("../../../assets/img/pallet4-content-image-mobile-app.png")}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="pallet4-contact">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-3">
							<h2 className="color--main">
								Do You have questions?
							</h2>
							<p>Are You interested in our product?</p>
						</div>
						<div className="col-lg-8 offset-lg-1">
							<img
								src={require("../../../assets/img/pallet4-content-image-contact.png")}
								alt=""
							/>
							<div className="pallet4-contact__row">
								<div>
									<Link
										to="/en/contact-us/"
										className="btn btn--white"
										data-hover="Contact us"
									>
										Contact us
										<span className="btn-arrows"></span>
									</Link>
								</div>
								<div>
									<a
										href={withPrefix(
											"/PalettenWerk_paleta_4-0_ulotka_a4_www_EN.pdf"
										)}
										target="_blank"
										rel="noreferrer"
										className="btn"
										data-hover="Download flyer"
									>
										Download flyer
										<span className="btn-arrows"></span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default SingleOffer;
